import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, BrowserRouter, Routes } from 'react-router-dom'

import { ROLES } from './utils/roles'

import { authorize } from './hocs/authorize'
import { authenticate } from './hocs/authenticate'

import { getPricesSettings } from './data/actions/settingsActions'

import NavBar from './components/NavigationBar'
import { Footer } from './components/Footer/Footer'
import { Notification } from './components/Notification/Notification'
import { LocationUpdateHandler } from './utils/reactRouterDom/LocationUpdateHandler'
import { ApplicationLoader } from './components/ApplicationLoader/ApplicationLoader'

import { Counter } from './pages/Counter'
import { FaqPage } from './pages/faq/FaqPage'
import { HomePage } from './pages/home/HomePage'
import { LoginPage } from './pages/login/LoginPage'
import { StoresPage } from './pages/stores/StoresPage'
import { LogoutPage } from './pages/logout/LogoutPage'
import { ProfilePage } from './pages/profile/ProfilePage'
import { PricingPage } from './pages/pricing/PricingPage'
// import { EntryModal } from './pages/entryModal/EntryModal'
import { ContactsPage } from './pages/contacts/ContactsPage'
import { RegisterPage } from './pages/register/RegisterPage'
import { SchedulesPage } from './pages/schedules/SchedulesPage'
import { AdminPanelPage } from './pages/adminPanel/AdminPanel'
import { HowItWorksPage } from './pages/howItWorks/HowItWorksPage'
import { DeliveryTimePage } from './pages/deliveryTime/DeliveryTimePage'
import { RegisterOrderPage } from './pages/registerOrder/RegisterOrderPage'
import { PrivacyPolicyPage } from './pages/privacyPolicy/PrivacyPolicyPage'
import { ForbiddenItemsPage } from './pages/fobiddenItems/ForbiddenItemsPage'
import { ForgetPasswordPage } from './pages/forgetPassword/ForgetPasswordPage'
import { WeightAndSizesPage } from './pages/weightAndSizes/WeightAndSizesPage'
import { RestorePasswordPage } from './pages/restorePassword/RestorePasswordPage'
import { TermsAndConditionsPage } from './pages/termsAndConditions/TermsAndConditionsPage'
import { UnregisteredOrdersPage } from './pages/unregisteredOrders/UnregisteredOrdersPage'
import { VerifyRegistrationPage } from './pages/verifyRegistration/VerifyRegistrationPage'

export function AppRouterComponent(props: any) {
    const { getPricesSettings } = props

    useEffect(() => {
        getPricesSettings()
    }, [getPricesSettings])

    return (
        <BrowserRouter>
            <ApplicationLoader />
            <Notification />
            <LocationUpdateHandler>
                <div className="main-application">
                    {/* <EntryModal /> */}
                    <NavBar />
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/qr" element={<HomePage />} /> {/* specially created to analyze how much visitors has come from scanning the QR code */}
                        <Route path="/faq" element={<FaqPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/counter" element={<Counter />} />
                        <Route path="/counter" element={<Counter />} />
                        <Route path="/stores" element={<StoresPage />} />
                        <Route path="/logout" element={<LogoutPage />} />
                        <Route path="/pricing" element={<PricingPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/contacts" element={<ContactsPage />} />
                        <Route path="/schedules" element={<SchedulesPage />} />
                        <Route path="/how-it-works" element={<HowItWorksPage />} />
                        <Route path="/delivery-time" element={<DeliveryTimePage />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                        <Route path="/register-package" element={<RegisterOrderPage />} />
                        <Route path="/forget-password" element={<ForgetPasswordPage />} />
                        <Route path="/forbidden-items" element={<ForbiddenItemsPage />} />
                        <Route path="/weight-and-sizes" element={<WeightAndSizesPage />} />
                        <Route path="/profile" element={authenticate(<ProfilePage />)()} />
                        <Route path="/verify-registration" element={<VerifyRegistrationPage />} />
                        <Route path="/restore-password/:token" element={<RestorePasswordPage />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                        <Route path="/unregistered-packages" element={<UnregisteredOrdersPage />} />
                        <Route path="/admin" element={authorize(ROLES.MODERATOR, <AdminPanelPage />)()} />
                    </Routes>
                </div>
                <div className="footer-main-container">
                    <Footer />
                </div>
            </LocationUpdateHandler>
        </BrowserRouter>
    )
}

const mapDispatchToProps = { getPricesSettings }

export const AppRouter = connect(null, mapDispatchToProps)(AppRouterComponent)
