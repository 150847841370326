import { useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Dialog,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    FormControl,
} from '@mui/material'

import { i18nAdminEditOrder } from './i18n'
import { PackagesManager } from '../PackagesManager'
import { GlobalState } from '../../../../../data/store'
import { texts } from '../../../../../utils/i18n/texts'
import { messages } from '../../../../../utils/i18n/messages'
import { getOrderStatusChip } from '../../../../../components/Shared'
import { uncombineOrders } from '../../../../../data/actions/ordersActions'
import { useTranslate, useTranslateSingle } from '../../../../../hooks/translate'
import { showNotification } from '../../../../../data/actions/notificationActions'
import { OrderStatus, ORDER_STATUS } from '../../../../../data/reducers/ordersReducer'
import { ConfirmActionModal } from '../../../../../components/ConfirmActionModal/ConfirmActionModal'

import './styles.css'

export function EditOrderModalComponent(props: any): JSX.Element {
    const { handleClose, data, handleSaveChanges } = props

    const dispatch = useDispatch()
    const [status, setStatus] = useState(data.status)
    const [packages, setPackages] = useState(data.packages || [])
    const [openConfirmUncombineOrdersModal, setOpenConfirmUncombineOrdersModal] = useState(false)

    const { loggedIn } = useSelector((state: GlobalState) => state.users)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const t = useTranslate(i18nAdminEditOrder)
    const kgLabel = useTranslateSingle(texts.kgs)
    const successCopy = useTranslateSingle(messages.successfullyCopiedToClipboard)

    const handleSave = () => handleSaveChanges(data._id, { 
        status,
        packages,
        type: data.type,
        linkedOrdersIds: data.linkedOrdersIds,
        deliveryCompany: data.deliveryCompany,
    })
    const handleUncombine = () => {
        props.uncombineOrders({ masterOrder: data, userId: loggedIn._id })
        handleClose()
    }
    const showConfirmUncombineModal = () => setOpenConfirmUncombineOrdersModal(true)
    const closeUncombineModal = () => setOpenConfirmUncombineOrdersModal(false)

    const handleChangeStatus = (ev: any) => setStatus(ev.target.value)
    const handleChangePackageWeight = (i: number, weight: string) => changePackageProp(i, 'weight', weight)
    const handleChangePackageSizes = (i: number, sizes: string) => changePackageProp(i, 'sizes', sizes)
    const handleChangePackageProcessed = (i: number, newValue: boolean) => changePackageProp(i, 'processed', newValue)
    const handleDeletePackage = (i: number) => {
        const packagesCopy = packages.slice()
        packagesCopy.splice(i, 1)
        setPackages(packagesCopy)
    }
    const handleAddPackage = () => {
        const newPackage = {
            weight: '1',
            sizes: '10x10x10',
            processed: false,
        }
        const copy = packages.slice()
        copy.push(newPackage)
        setPackages(copy)
    }

    const changePackageProp = (index: number, propName: string, value: any) => {
        const pckg = { ...packages[index] }
        pckg[propName] = value
        const packagesCopy = packages.slice()
        packagesCopy.splice(index, 1, pckg)
        setPackages(packagesCopy)
    }

    const handleCopyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
        dispatch(showNotification(successCopy, 'success'))
    }

    const statuses = Object.keys(ORDER_STATUS) as OrderStatus[]

    const totalWeight =
        data.packages.length > 1
            ? data.packages.reduce((a: any, b: any) => +a + +b.weight, 0)
            : data.packages[0]?.weight ?? 0

    const masterClass = data.type === 'MASTER' ? 'master-order' : ''

    return (
        <Dialog open={true} onClose={handleClose} scroll="body">
            <div className={`modal-container ${masterClass}`}>
                <Typography variant="h5">
                    {t.title} <strong>{data.receiver}</strong>
                </Typography>
                <Typography variant="h6">
                    <strong>{data.registerDate} - {data.orderId}</strong>
                </Typography>
                <hr />
                <Typography>
                    {t.count}: <strong>{data.packages.length}</strong>
                </Typography>
                <Typography className='copiable-text'>
                    {t.totalWeight}:
                    <span className='copy-to-clipboard-wrapper' onClick={() => handleCopyToClipboard(totalWeight)}>
                        <strong>{totalWeight} {kgLabel}</strong>
                    </span>
                </Typography>
                <Typography>
                    {t.origin}: <strong>{data.siteOrigin}</strong>
                </Typography>
                <Typography>
                    {t.valueEur}: <strong>{data.valueEur}</strong>
                </Typography>
                <Typography>
                    {t.description}: <strong>{data.description}</strong>
                </Typography>
                <Typography>
                    {t.reference}: <strong>{data.referenceNumberOrInvoice}</strong>
                </Typography>
                <Typography>
                    {t.transactionDate}: <strong>{data.transactionDate}</strong>
                </Typography>
                <Typography>
                    {t.itDeliveryCompany}: <strong>{data.itDeliveryCompany}</strong>
                </Typography>
                <Typography>
                    {t.trackingNumber}: <strong>{data.trackingNumber}</strong>
                </Typography>
                <hr />
                <Typography className='copiable-text'>
                    {t.deliveryTo}:
                    <span className='copy-to-clipboard-wrapper'>
                        <strong>{data.deliveryCompany}, {data.deliverTo}</strong>
                    </span>
                </Typography>
                <Typography className='copiable-text'>
                    {t.address}:
                    <span className='copy-to-clipboard-wrapper' onClick={() => handleCopyToClipboard(data.deliveryAddress)}>
                        <strong>{data.deliveryAddress}</strong>
                    </span>
                </Typography>
                <Typography className='copiable-text'>
                    {t.phone}:
                    <span className='copy-to-clipboard-wrapper' onClick={() => handleCopyToClipboard(data.receiverPhone)}>
                        <strong>{data.receiverPhone}</strong>
                    </span>
                </Typography>
                <Typography className='copiable-text'>
                    {t.email}:
                    <span className='copy-to-clipboard-wrapper' onClick={() => handleCopyToClipboard(data.email)}>
                        <strong>{data.email}</strong>
                    </span>
                </Typography>
                <FormControl margin="dense" fullWidth size="small">
                    <InputLabel id="status-select-label">{t.status}</InputLabel>
                    <Select labelId="status-select-label" onChange={handleChangeStatus} value={status} label={t.status}>
                        {statuses.map((orderStatus, i) => {
                            if (orderStatus === 'LINKED') {
                                return []
                            }

                            return (
                                <MenuItem key={i} value={orderStatus}>
                                    {getOrderStatusChip(orderStatus, lang)}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <hr />
                <PackagesManager
                    handleChangePackageProcessed={handleChangePackageProcessed}
                    handleChangePackageWeight={handleChangePackageWeight}
                    handleChangePackageSizes={handleChangePackageSizes}
                    handleDeletePackage={handleDeletePackage}
                    deliveryCompany={data.deliveryCompany}
                    handleAddPackage={handleAddPackage}
                    unregistered={data.unregistered}
                    packages={packages}
                    status={status}
                />
                <div className="action-buttons">
                    {
                        data.type === 'MASTER' && data.linkedOrdersIds?.length ?
                            <Button onClick={showConfirmUncombineModal} variant="contained" color="warning">
                                {t.buttonLabel0}
                            </Button>
                            :
                            <></>
                    }
                    <Button onClick={handleSave} variant="contained" color="secondary">
                        {t.buttonLabel1}
                    </Button>
                    <Button onClick={handleClose} variant="contained" color="info">
                        {t.buttonLabel2}
                    </Button>
                </div>
            </div>
            <ConfirmActionModal
                type='confirm'
                text={t.confirmUncombineText}
                confirmCallback={handleUncombine}
                handleClose={closeUncombineModal}
                isOpen={openConfirmUncombineOrdersModal}
            />
        </Dialog>
    )
}

const mapDispatchToProps = { uncombineOrders }

export const EditOrderModal = connect(null, mapDispatchToProps)(EditOrderModalComponent)
