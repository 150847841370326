import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import {
    Table,
    Paper,
    Modal,
    Button,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Typography,
    TableContainer,
} from '@mui/material'

import { tableStruct } from './tableUtils'
import { i18nAdminISaddOrdersModal } from './i18n'
import { GlobalState } from '../../../../data/store'
import { texts } from '../../../../utils/i18n/texts'
import { useTranslate, useTranslateSingle } from '../../../../hooks/translate'
import { Order, ORDER_STATUS } from '../../../../data/reducers/ordersReducer'
import { calculateTransportationPrice, getFormattedDate, mapPackagesStringSizesToPackagesObjectsSizes } from '../../../../utils/functions'

import './styles.css'

export function AddOrdersModal(props: any): JSX.Element {
    const { handleClose, data, handleSaveChanges } = props

    const [pickedOrders, setPickedOrders] = useState(data.orders || ([] as string[]))
    const { orders } = useSelector((state: GlobalState) => state.orders)
    const prices = useSelector((state: GlobalState) => state.settings.prices)
    const lang = useSelector((state: GlobalState) => state.lang.currentSelection)

    const t = useTranslate(i18nAdminISaddOrdersModal)
    const heads = Object.values(t).slice(2, 9)
    const currency = useTranslateSingle(texts.currency)
    
    const unpickedOrders = orders.filter(
        (x) => x.status === ORDER_STATUS.ARRIVED_BG && x.deliveryCompany === data.deliveryCompany
    )

    const pickup = getFormattedDate(true, true, true, false, data.pickupDate)
    const delivery = getFormattedDate(true, true, true, false, data.deliveryDate)
    const scheduleInfo = `${pickup} > ${delivery}`

    const handleClickOrder = (row: Order) => {
        if (isPicked(row)) {
            unpick(row)
        } else {
            pick(row)
        }
    }

    const pick = (row: Order) => {
        if (row.type === 'MASTER' && row.linkedOrdersIds?.length) {
            const newIds = [row._id, ...row.linkedOrdersIds]
            setPickedOrders([...pickedOrders, ...newIds])
        } else {
            setPickedOrders([...pickedOrders, row._id])
        }
    }

    const unpick = (row: Order) => {
        if (row.type === 'MASTER' && row.linkedOrdersIds?.length) {
            const idsToUnpick = [row._id, ...row.linkedOrdersIds]
            const copy = pickedOrders.slice()
            idsToUnpick.forEach(orderId => {
                const index = pickedOrders.findIndex((id: string) => id === orderId)
                copy.splice(index, 1)
            })
            setPickedOrders(copy)
        } else {
            const index = pickedOrders.findIndex((id: string) => id === row._id)
            const copy = pickedOrders.slice()
            copy.splice(index, 1)
            setPickedOrders(copy)
        }
    }

    const isPicked = (row: Order) => pickedOrders.findIndex((id: string) => id === row._id) !== -1

    const handleSave = () => handleSaveChanges({ ...data, orders: pickedOrders })

    const tableStructure = tableStruct(heads, lang)

    const totalPrice = (() => {
        let calcPrice = 0
        for (const pickedOrderId of pickedOrders) {
            const order = orders.find((x) => x._id === pickedOrderId)
            if (order) {
                const isTuttoDelivery = order.deliveryCompany === 'tuttopost'
                const packagesInfo = mapPackagesStringSizesToPackagesObjectsSizes(order.packages)
                const price = calculateTransportationPrice(packagesInfo, prices, isTuttoDelivery, !!order.unregistered)
                calcPrice += price
            }
        }
        return Number(calcPrice).toFixed(2)
    })()

    return (
        <Modal className='admin-add-orders-to-is-modal' open={true} onClose={handleClose}>
            <div className="internal-schedules-orders-modal-container">
                <Typography className="modal-header" variant="h4" align="center">
                    {t.title} <strong>{data.deliveryCompany}</strong>
                    <Typography variant="body1" align="center">
                        {scheduleInfo}
                    </Typography>
                </Typography>
                <Typography variant="h5" align="center">
                    {t.totalValue}:{' '}
                    <strong>
                        {totalPrice} {currency}
                    </strong>
                </Typography>
                <TableContainer component={Paper} className="orders-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableStructure.map((x: any, i: number) => {
                                    return (
                                        <TableCell key={i} align="center">
                                            <Typography variant="h6">{x.head}</Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {unpickedOrders.map((row, i: number) => (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    className={isPicked(row) ? 'picked-row' : 'unpicked-row'}
                                    onClick={() => handleClickOrder(row)}
                                    key={i}
                                >
                                    {tableStructure.map((x: any) => (
                                        <TableCell key={uuid()} align="center">
                                            {x.getData(row, prices)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="action-buttons">
                    <Button onClick={handleSave} variant="contained" color="secondary">
                        {t.buttonLabel1}
                    </Button>
                    <Button onClick={handleClose} variant="contained" color="info">
                        {t.buttonLabel2}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
