import { useState } from 'react'
import { BottomNavigation, BottomNavigationAction, Box, Paper } from '@mui/material'
import { ManageAccounts, Inventory, AirportShuttle, LocalShipping } from '@mui/icons-material'

import { i18nAdmin } from '../i18n'
import { AdminTabs } from '../AdminPanel'
import { useTranslate } from '../../../hooks/translate'
import { UsersSector } from '../usersSector/UsersSector'
import { TabPanel } from '../../../components/TabPanel/TabPanel'
import { InternalSchedules } from '../internalSchedules/InternalSchedules'
import { ActiveOrdersSector } from '../activeOrdersSector/ActiveOrdersSector'
import { CrossBorderSchedules } from '../crossBorderSchedules/CrossBorderSchedules'

import './styles.css'

export function AdminPanelMobile(): JSX.Element {
    const [tab, setTab] = useState(AdminTabs.ORDERS)

    const handleChange = (ev: any, value: any) => setTab(value)

    const t = useTranslate(i18nAdmin)

    return (
        <Box>
            <TabPanel boxClassName='admin-tab-panel-mobile' value={tab} index={AdminTabs.USERS}>
                <UsersSector />
            </TabPanel>
            <TabPanel boxClassName='admin-tab-panel-mobile' value={tab} index={AdminTabs.ORDERS}>
                <ActiveOrdersSector />
            </TabPanel>
            <TabPanel boxClassName='admin-tab-panel-mobile' value={tab} index={AdminTabs.INTERNAL_SCHEDULES}>
                <InternalSchedules />
            </TabPanel>
            <TabPanel boxClassName='admin-tab-panel-mobile' value={tab} index={AdminTabs.CROSS_BORDER_SCHEDULES}>
                <CrossBorderSchedules />
            </TabPanel>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    // showLabels
                    value={tab}
                    onChange={handleChange}
                >
                    <BottomNavigationAction label={t.users} icon={<ManageAccounts />} />
                    <BottomNavigationAction label={t.orders} icon={<Inventory />} />
                    <BottomNavigationAction label={t.internalSchedulesMobile} icon={<AirportShuttle />} />
                    <BottomNavigationAction label={t.crossBorderSchedulesMobile} icon={<LocalShipping />} />
                </BottomNavigation>
            </Paper>
        </Box>
    )
}
