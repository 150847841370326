import { createAsyncThunk } from "@reduxjs/toolkit"

import { store } from "../store"
import { isAuthRequest } from "./utils"
import { ActionTypes } from "../actionTypes"
import { getTranslation } from "./translation"
import { messages } from "../../utils/i18n/messages"
import { IPackage } from "../reducers/ordersReducer"
import { hideLoader, showLoader } from "./loaderActions"
import { showNotification } from "./notificationActions"
import { econtService } from "../../services/econtService"

export const getEcontOffices = createAsyncThunk(
    ActionTypes.econt.getOffices.typeName,
    async (data: any, thunkAPI) => {
        const { dispatch } = thunkAPI
        const translatedMsgs = getTranslation(messages)
        try {
            dispatch(showLoader())
            const response = await econtService.getOffices()
            if (!isAuthRequest(response, dispatch)) {
                return
            }

            return response.data
        } catch (err) {
            console.log(err)
            dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
        } finally {
            dispatch(hideLoader())
        }
    }
)

interface ILabelOperationsInterface {
    senderName: string
    senderPhone: string
    senderAddress: any
    receiverName: string
    receiverPhone: string
    receiverAddress: any
    packages: IPackage[]
    requiredPayment: number
}

export const validateEcontLabel = createAsyncThunk(
    ActionTypes.econt.validateLabel.typeName,
    async (data: ILabelOperationsInterface, thunkAPI) => {
        const { dispatch } = thunkAPI
        const translatedMsgs = getTranslation(messages)
        try {
            dispatch(showLoader())
            const response = await econtService.validateLabel(data)
            if (!isAuthRequest(response, dispatch)) {
                return
            }

            if (response.isSuccess) {   
                dispatch(showNotification('VALID', 'success'))
            } else {
                dispatch(showNotification('INVALID', 'error'))
            }

            return response.data
        } catch (err) {
            console.log(err)
            dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
        } finally {
            dispatch(hideLoader())
        }
    }
)

export const createEcontLabel = createAsyncThunk(
    ActionTypes.econt.createLabel.typeName,
    async (data: ILabelOperationsInterface, thunkAPI) => {
        const { dispatch } = thunkAPI
        const translatedMsgs = getTranslation(messages)
        try {
            dispatch(showLoader())
            const response = await econtService.createLabel(data)
            if (!isAuthRequest(response, dispatch)) {
                return
            }

            // @ts-ignore
            const { shipmentNumber, totalPrice, pdfURL } = response.data.label

            const textContnt = `${shipmentNumber} - ${totalPrice}`
            dispatch(showNotification(textContnt, 'success'))

            if (store.getState().settings.defaults.shouldAutoOpenSuccessfullyCreatedEcontLabelsInNewTab) {
                setTimeout(() => {
                    window.open(pdfURL)
                }, 1000)
            }

            return response.data
        } catch (err) {
            console.log(err)
            dispatch(showNotification(translatedMsgs.unexpectedError, 'error'))
        } finally {
            dispatch(hideLoader())
        }
    }
)
