export const i18nAdmin = {
	users: {
		bg: 'Потребители',
		en: 'Users'
	},
	orders: {
		bg: 'Пратки',
		en: 'Packages'
	},
	internalSchedules: {
		bg: 'Вътрешни графици',
		en: 'Internal schedules'
	},
	crossBorderSchedules: {
		bg: 'Международни графици',
		en: 'Cross border schedules'
	},
	internalSchedulesMobile: {
		bg: 'Вътрешни',
		en: 'Internal'
	},
	crossBorderSchedulesMobile: {
		bg: 'Международни',
		en: 'Cross border'
	},
	statistics: {
		bg: 'Статистика',
		en: 'Statistics'
	},
	settings: {
		bg: 'Настройки',
		en: 'Settings'
	},
	archive: {
		bg: 'Архив',
		en: 'Archive'
	}
}
